import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/analyses',
    name: 'Analyses',
    component: () => import('../views/Analyses.vue')
  },
  {
    path: '/analysis/:hash',
    name: 'AnalysisDetails',
    component: () => import('../views/AnalysisDetails.vue')
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('../views/Jobs.vue')
  },
  {
    path: '/runners',
    name: 'Runners',
    component: () => import('../views/Runners.vue')
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import('../views/Upload.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
