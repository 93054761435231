<template>
  <v-app>
    <v-app-bar app color="primary" short dark>
      <v-toolbar-title class="mr-4">MassMalAn</v-toolbar-title>
      <v-btn class="mr-2" to="/" text> Dashboard </v-btn>
      <v-btn class="mr-2" to="/analyses" text> Analyses </v-btn>
      <v-btn class="mr-2" to="/jobs" text> Jobs </v-btn>
      <v-btn class="mr-2" to="/runners" text> Runners </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="ml-2" to="/upload" outlined color="white" small fab>
        <v-icon>mdi-upload</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
};
</script>