<template>
  <v-container class="col-9">
    <v-card>
      <v-card-title>Dashboard</v-card-title>
      <v-card-text v-if="stats">
        <v-row>
          <v-col>
            <v-card outlined class="mb-2">
              <v-card-title>Analyses</v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tr>
                    <td>Files analyzed</td>
                    <td class="text-right">{{ stats.analysis.total }}</td>
                  </tr>
                  <tr style="height: 21px">
                    <td></td>
                    <td></td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
            <v-card outlined class="mb-2">
              <v-card-title>Jobs</v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tr>
                    <td>Running jobs</td>
                    <td class="text-right">{{ stats.jobs.running }}</td>
                  </tr>
                  <tr>
                    <td>Total jobs</td>
                    <td class="text-right">{{ stats.jobs.total }}</td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined class="mb-2">
              <v-card-title>Runners</v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tr>
                    <td>Online runners</td>
                    <td class="text-right">{{ stats.runners.online }}</td>
                  </tr>
                  <tr>
                    <td>Total runners</td>
                    <td class="text-right">{{ stats.runners.total }}</td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
            <v-card outlined class="mb-2">
              <v-card-title>Storage</v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <tr>
                    <td>Used storage</td>
                    <td class="text-right">{{ stats.storage.used }}</td>
                  </tr>
                  <tr>
                    <td>Free storage</td>
                    <td class="text-right">{{ stats.storage.free }}</td>
                  </tr>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else> Loading... </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import filesize from "filesize";

export default {
  name: "Dashboard",
  data() {
    return {
      stats: null,
    };
  },
  mounted() {
    axios.get(this.$api_base_url + "/dashboard").then((response) => {
      this.stats = response.data.stats;
      this.stats.storage.used = filesize(this.stats.storage.used);
      this.stats.storage.free = filesize(this.stats.storage.free);
    });
  },
};
</script>